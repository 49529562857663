<template>
  <!-- 线上课程 -->
  <div class="virtualsimulation">
    <div class="head">
      <div class="searchcontain">
        <input class="elinput" v-model="queryParams.keyword" placeholder="请输入课程名称" type="text" />
        <div class="searchbt" @click="searchbt">搜索</div>
      </div>
    </div>
    <div class="itemcontain">
      <template v-if="datalist">
        <div class="item" v-for="(item, index) in datalist" :key="index">
          <el-image
            class="elimage"
            :src="item.home_cover ? item.home_cover : item.cover"
            @click="todetail(item)"
            style="cursor: pointer"
          />
          <div class="item-right">
            <div class="title" @click="todetail(item)" style="cursor: pointer">{{ item.name }}</div>
            <div class="nameicon" @click="todetail(item)" style="cursor: pointer">
              <!-- <el-image class="icon" :src="icon1" /> -->
              <div class="name">
                <span>主讲老师：</span>
                {{
                item.teacher_user_info
                ? item.teacher_user_info.name
                : ""
                }}
              </div>
            </div>

            <div class="bottomcontain" v-if="Number(item.child_type) === 0">
              <div class="left" @click="todetail(item)" style="cursor: pointer">
                <div class="contain">
                  <!-- <el-image class="icon" :src="icon2" /> -->
                  <div class="name">学习进度</div>
                  <div class="elprogress">
                    <el-progress
                      :stroke-width="15"
                      :percentage="
                      item.study_progress
                        ? parseInt(item.study_progress * 100)
                        : 0
                    "
                    ></el-progress>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="item" @click="resultsbt(item)">
                  <el-image class="elimage" :src="image1" />
                  <div class="name">课程成绩</div>
                </div>
                <!-- <div class="line"></div> -->
                <div class="item" @click="interactiveqabt(item)">
                  <el-image class="elimage" :src="image2" />
                  <div class="name">互动问答</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="elpagination" v-if="total > 0">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="queryParams.page"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getmycourselist } from "@/api/course"
export default {
  name: "virtualsimulation",
  components: {},
  created () {
    this.getmycourselist()
  },
  data () {
    return {
      total: 0,
      queryParams: {
        keyword: "",
        page: 1,
        per_page: 10,
      },
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/center/时间 钟表.png"),
      image1: require("@/assets/new/课程.实验成绩.png"),
      image2: require("@/assets/coursedetails/center/answers.png"),
      datalist: [],
    }
  },
  methods: {
    searchbt () {
      this.queryParams.page = 1
      this.getmycourselist()
    },
    changePage (val) {
      this.queryParams.page = val
      this.getmycourselist()
    },
    resultsbt (item) {
      this.$router.push({
        path: "/home/personalcenter/courseresults",
        query: {
          study_progress: item.study_progress,
          course_sn: item.course_sn,
          id: item.id,
        },
      })
    },
    reportbt () {
      this.$router.push({
        path: "/home/personalcenter/experimentalreport",
      })
    },

    interactiveqabt (item) {
      this.$router.push({
        path: "/home/personalcenter/courseinteractiveqa",
        query: {
          course_sn: item.course_sn,
          name: item.name
        },
      })
    },

    getmycourselist () {
      getmycourselist(this.queryParams)
        .then((response) => {
          this.total = response.data.total
          this.datalist = response.data.data
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    todetail (item) {
      window.localStorage.setItem("menuId", 2)
      this.$store.dispatch("setmenuid", 2)
      if (Number(item.child_type)) { // 耕读类型
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/coursedetails?course_sn=" +
            item.course_sn + '&courseType=' + item.child_type,
        })
      } else {
        //已经加入课程
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/courselearning?course_sn=" +
            item.course_sn,
        })
      }
    },
  },
};
</script>
<style scoped lang="scss">
.virtualsimulation {
  padding: 20px;

  .head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 22px;

    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #288add;
      user-select: none;
      white-space: nowrap;
    }

    .searchcontain {
      // display: flex;
      // justify-content: flex-end;
      // align-items: center;
      // border: 1px solid red;
      position: relative;

      .elinput {
        width: 260px;
        height: 28px;
        border-radius: 25px;
        padding-left: 20px;
        border: 1px solid #3d84ff;

        &:focus {
          outline: none;
        }
      }

      .searchbt {
        position: absolute;
        top: 0px;
        right: 0px;
        margin-left: 17px;
        width: 85px;
        height: 32px;
        background: #3d84ff;
        border-radius: 25px;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        user-select: none;

        &:active {
          background: #26b7ffa9;
        }
      }
    }
  }

  .itemcontain {
    // border: 1px solid red;
    // margin-top: 30px;
    // height: 234px;
    background: #ffffff;
    padding: 20px;
    box-sizing: border-box;

    .item {
      // border: 1px solid red;
      height: 200px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 10px;

      .elimage {
        width: 313px;
        height: 100%;
        background: linear-gradient(0deg, #9778af 0%, #ffffff 100%);
        border-radius: 4px;
      }

      .item-right {
        // border: 1px solid red;
        height: 100%;
        flex: 1;
        margin-left: 30px;

        .title {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #222222;
        }

        .nameicon {
          margin-top: 24px;
          display: flex;
          justify-content: flex-start;
          align-content: center;

          .icon {
            margin-right: 10px;
            width: 23px;
            height: 20px;
          }

          .name {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }

        .bottomcontain {
          // border: 1px solid red;
          margin-top: -10px;
          // height: 85px;
          display: flex;
          justify-content: space-between;
          align-content: flex-end;

          .left {
            // border: 1px solid red;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;

            .contain {
              //   border: 1px solid red;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              // width: 300px;
              //   position: relative;
              .icon {
                margin-right: 10px;
                width: 22px;
                height: 22px;
              }

              .name {
                margin-right: 10px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }

              .elprogress {
                margin-left: 10px;
                width: 400px;
              }

              .time {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
            }
          }

          .right {
            // border: 1px solid red;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .item {
              // border: 1px solid red;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
              user-select: none;
              cursor: pointer;
              width: 160px;

              .elimage {
                width: 57px;
                height: 57px;
                border-radius: 50%;
              }

              .name {
                font-size: 16px;
                font-weight: 500;
                color: #666666;
                margin-top: 20px;
              }
            }

            .line {
              margin-left: 40px;
              margin-right: 40px;
              height: 85px;
              border-right: 1px solid #e7e7e7;
            }
          }
        }
      }
    }
  }

  .elpagination {
    margin-top: 68px;
    display: flex;
    justify-content: center;
    align-items: center;

    .elpagination-title {
      margin-right: 4px;
    }
  }

  ::v-deep .el-progress-bar {
    margin-right: -70px;
  }

  ::v-deep .el-progress__text {
    float: right;
  }
}
</style>

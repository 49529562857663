<template>
  <!-- 开放实验安排-->
  <div class="virtualsimulation">
    <div class="search">
      <div class="leftcontain">
        <div class="left1">
          <div class="text">学期:</div>
          <el-select v-model="form.semester_id" placeholder="请选择" @change="change">
            <el-option
              v-for="item in optionone"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="left2">
          <div class="text">起始周:</div>
          <el-select v-model="form.start_week" placeholder="请选择">
            <el-option v-for="item in is_current_weeknum" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="left2">
          <div class="text">截止周:</div>
          <el-select v-model="form.end_week" placeholder="请选择">
            <el-option v-for="item in is_current_weeknum" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div class="rightcontain">
        <el-button type="primary" @click="searchbt" style="background:#3d84ff">查询</el-button>
        <el-button type="info" @click="resetbt">重置</el-button>
      </div>
    </div>
    <el-table
      ref="singleTable"
      :data="tableData"
      highlight-current-row
      style="width: 100%"
      class="Table"
      stripe
      :header-cell-style="{ 'font-size': '15px', color: '#666666', 'font-weight': 'bold', background: '#F7F7F7', }"
      :row-style="{ 'font-size': '15px', color: '#222222', 'font-weight': '400', }"
    >
      <el-table-column prop="experiment_name" label="实验项目" show-overflow-tooltip></el-table-column>
      <el-table-column prop="course_lib_name" label="所属课程" show-overflow-tooltip></el-table-column>
      <el-table-column prop="college_name" label="学院" show-overflow-tooltip></el-table-column>
      <el-table-column prop="lab_name" label="实验室" show-overflow-tooltip></el-table-column>
      <el-table-column prop="lab_room_name" label="实验用房" show-overflow-tooltip></el-table-column>
      <el-table-column prop="week_number" label="周次" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>第{{ scope.row.week_number }}周</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="星期" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>星期{{ scope.row.date }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lesson_name" label="节次" show-overflow-tooltip></el-table-column>
      <el-table-column prop="attendance_status" label="考勤" show-overflow-tooltip>
        <template
          slot-scope="scope"
          v-if="scope.row.is_show === 0"
        >{{ scope.row.attendance_status | attendancestatus }}</template>
      </el-table-column>
      <el-table-column prop="report_status" label="实验报告" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.report_status == -1">未提交</span>
          <span v-if="scope.row.report_status == 0">未批阅</span>
          <span v-if="scope.row.report_status == 1">已驳回</span>
          <span v-if="scope.row.report_status == 2">已批阅</span>
        </template>
        <!-- <template slot-scope="scope" v-else>
          {{ scope.row.report_status | reportstatus }}
        </template>-->
      </el-table-column>
      <el-table-column prop="total_score" label="总成绩" show-overflow-tooltip>
        <template slot-scope="scope" v-if="scope.row.is_show === 0">
          {{ scope.row.total_score.total_score == -1 ? "-" : scope.row.total_score.total_score
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <div class="item-right">
            <el-button
              type="text"
              v-if="scope.row.report_status == 2"
              @click="editorbt(scope.row, 'check')"
            >查看报告</el-button>
            <el-button
              type="text"
              v-if="scope.row.report_status == -1"
              @click="editorbt(scope.row, 'add')"
            >添加报告</el-button>
            <el-button
              type="text"
              v-if="scope.row.report_status == 1 || scope.row.report_status == 0"
              @click="editorbt(scope.row, 'edit')"
            >编辑报告</el-button>
            <el-button type="text" @click="homework(scope.row)">作业</el-button>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="experiment_name" label="实验项目" show-overflow-tooltip></el-table-column>
      <el-table-column prop="teacher_name" label="指导老师" show-overflow-tooltip></el-table-column>-->
    </el-table>
    <div class="elpagination" v-if="total > 0">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="form.page"
        @current-change="pageChange"
      ></el-pagination>
    </div>

    <Customdialog
      ref="customdialog"
      width="900px"
      type="max2"
      class="customdialog"
      title="实验报告"
      :showclose="true"
    >
      <div slot="dialogbody" class="dialogbody">
        <Editorreport v-if="flag" ref="editorpanel" :close="cancel" :fresh="searchbt"></Editorreport>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="bts">
          <div class="bt" @click="cancel">取消</div>
          <div class="bt bt1" @click="save" v-if="type != 'check'">保存</div>
          <div class="bt bt1" @click="submitbt" v-if="type != 'check'">提交</div>
        </div>
      </div>
    </Customdialog>
  </div>
</template>

<script>
const report_status = ["未提交", "未批阅", "已驳回", "已批阅"]
const attendance_status = ["正常", "旷课", "迟到", "早退", "迟到并早退"]
import { getmybook } from "@/api/openexperimentbook"
import { gettreelist } from "@/api/common/semester"
import Customdialog from "@/components/customdialog.vue"
import Editorreport from "./component/editorreport.vue"
import dayjs from "dayjs"
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
    Editorreport,
  },
  filters: {
    dataformat (value) {
      if (!value) return ""
      let time = dayjs.unix(value * 1000).format("MM,DD")
      return "(" + time + ")"
    },
    reportstatus (val) {
      return report_status[val]
    },
    attendancestatus (val) {
      if (val != null) {
        return attendance_status[val]
      } else {
        return "-"
      }
    },
  },
  data () {
    return {
      total: 0,
      form: {
        start_week: "",
        end_week: "",
        status: 2,
        semester_id: "",
        is_count: 1,
        page: 1,
        per_page: 10,
      },
      optionone: [],
      tableData: [],
      options: [],
      is_current_weeknum: "",
      flag: false,
      type: "",
      loading: null,
      btnText: '',
    }
  },
  created () {
    this.gettreelist()
  },
  methods: {
    /**开启全屏loading **/
    startLoading () {
      this.loading = this.$loading({
        lock: true,
        text: `报告${this.btnText}中...`,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      })
    },
    /**关闭loading**/
    closeLoading () {
      setTimeout(() => {
        this.loading.close()
      }, 500)
    },
    change (item) {
      this.form.semester_id = item
      this.is_current_weeknum = this.optionone.find(val => val.value == item).week_number

    },
    searchbt () {
      this.getmybook()
    },
    resetbt () {
      this.form = {
        start_week: "",
        end_week: "",
        semester_id: "",
        status: 2,
        is_count: 1,
        page: 1,
        per_page: 10,
      }
      this.gettreelist()
    },
    editorbt (item, type) {
      this.type = type
      this.$refs.customdialog.dialogopenbt()
      this.flag = false
      this.$nextTick(() => {
        this.flag = true
        setTimeout(() => {
          this.$refs.editorpanel.getinfo(item, type)
        }, 1000)
      })
    },
    cancel () {
      if (this.loading) {
        this.closeLoading()
      }
      this.$refs.customdialog.dialogclosebt()
    },
    save () {
      this.btnText = "保存"
      this.startLoading()
      this.$refs.editorpanel.savebt()
    },
    submitbt () {
      this.btnText = "提交"
      this.startLoading()
      this.$refs.editorpanel.eidtorbt()
    },
    homework (row) {
      this.$router.push({
        path: "/home/personalcenter/homework",
        query: {
          related_id: row.course_lib_weekly_lesson_id,
        }
      })
    },
    pageChange (data) {
      this.form.page = data
      this.getmybook()
    },
    reportbt () {
      this.$router.push({
        path: "/personalcenter/experimentalreport",
      })
    },
    formatSelect (_data) {
      this.optionone = _data.map(item => {
        return {
          label: item.start_year + '-' + item.end_year + '─' + (item.nper == 1 ? '第一学期' : '第二学期'),
          value: item.id,
          is_current: item.is_current,
          week_number: item.week_number
        }
      })
      this.optionone.forEach(el => {
        if (el.is_current == 1) {
          this.form.semester_id = el.value
          this.is_current_weeknum = el.week_number
          this.getmybook()
        }
      })
    },
    async gettreelist (params) {
      this.optionone = []
      const condition = sessionStorage.getItem('semester_all')
      if (condition) {
        let data = JSON.parse(condition)
        this.formatSelect(data.data)
      } else {
        await gettreelist(params)
          .then((response) => {
            if (response.code === 0 && response.data) {
              this.formatSelect(response.data.data)
            }
          })
          .catch((error) => { })
      }
    },
    getmybook () {
      this.tableData = []
      getmybook(this.form)
        .then((response) => {
          if (response.code === 0) {
            if (response.data.data && response.data.data.length > 0) {
              this.tableData = response.data.data
              this.total = response.data.total
            }
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
  },
};
</script>
<style scoped lang="scss">
.virtualsimulation {
  .search {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;

    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
          margin-right: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #888888;
        }

        .elinput {
          width: 200px;

          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
          margin-right: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #888888;
        }

        .elinput {
          width: 300px;
        }
      }
    }

    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;

      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }

      .bt1 {
        margin-right: 10px;
        margin-left: 10px;
        background: #3d84ff;
        color: white;
      }
    }
  }

  .Table {
    margin-top: 50px;
    width: 100%;

    .item-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .title {
        margin-left: 10px;
        font-size: 15px;
        font-weight: 400;
        color: #222222;
      }
    }

    .item-middle {
      .title {
        font-size: 15px;
        font-weight: 400;
        color: #222222;
      }
    }

    .bt {
      font-size: 15px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
    }

    .item-right {
      display: flex;
      justify-content: center;
      align-items: center;

      .bt {
        font-size: 15px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }

      .line2 {
        margin-right: 8px;
        margin-left: 8px;
        height: 14px;
        border-right: 2px solid #cccccc;
      }
    }
  }

  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .elpagination-title {
      margin-right: 4px;
    }
  }

  .customdialog {
    .dialogfooter {
      height: 100%;

      .bts {
        border-top: 1px solid #cccccc;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .bt {
          margin-right: 10px;
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          font-size: 18px;
          font-weight: 400;
          color: #a0a0a0;
          user-select: none;
          cursor: pointer;
          font-size: 14px;
        }

        .bt1 {
          background: #3d84ff;
          color: #ffffff;
          border: 1px solid #3d84ff;
        }
      }
    }
  }

  ::v-deep .el-button--text {
    color: rgb(61, 132, 255);
  }
}
</style>

<template>
  <!-- 预约记录-->
  <div class="virtualsimulation">
    <div class="search">
      <div class="leftcontain">
        <div class="left1">
          <div class="text">学期:</div>
          <el-select v-model="queryParams.semester_id" placeholder="请选择" @change="change">
            <el-option
              v-for="item in optionone"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="left2">
          <div class="text">状态:</div>
          <el-select v-model="queryParams.status" placeholder="请选择">
            <el-option
              v-for="item in optionstatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="left2">
          <div class="text">起始周:</div>
          <el-select v-model="queryParams.start_week" placeholder="请选择">
            <el-option v-for="item in is_current_weeknum" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="left2">
          <div class="text">截止周:</div>
          <el-select v-model="queryParams.end_week" placeholder="请选择">
            <el-option v-for="item in is_current_weeknum" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div class="rightcontain">
        <el-button type="primary" @click="searchbt" style="background:#3d84ff;margin-left: 10px;">查询</el-button>
        <el-button type="info" @click="resetbt">重置</el-button>
        <!-- <div class="bt" @click="resetbt">重置</div>
        <div class="bt bt1" @click="searchbt">查询</div>-->
      </div>
    </div>

    <el-table
      ref="singleTable"
      :data="tableData"
      v-loading="loading"
      highlight-current-row
      style="width: 100%"
      class="Table"
      stripe
      :header-cell-style="{ 'font-size': '15px', color: '#666666', 'font-weight': 'bold', background: '#F7F7F7'}"
      :row-style="{ 'font-size': '15px', color: '#222222', 'font-weight': '400', }"
    >
      <el-table-column prop="experiment_name" label="实验项目" show-overflow-tooltip></el-table-column>
      <el-table-column prop="course_lib_name" label="所属课程" show-overflow-tooltip></el-table-column>
      <el-table-column prop="college_name" label="学院" show-overflow-tooltip></el-table-column>
      <el-table-column prop="lab_name" label="实验室" show-overflow-tooltip></el-table-column>
      <el-table-column prop="lab_room_name" label="实验用房" show-overflow-tooltip></el-table-column>
      <el-table-column prop="week_number" label="周次" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>第{{ scope.row.week_number }}周</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="星期" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>星期{{ scope.row.date }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lesson_name" label="节次" show-overflow-tooltip></el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template slot-scope="scope">{{ scope.row.status | formatstatus }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="item-right">
            <el-button
              type="text"
              @click="cancelappointment(scope.row)"
              :disabled="scope.row.status == 1 || scope.row.status == 3 || scope.row.status == 4"
            >取消预约</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="elpagination" v-if="total > 0">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="queryParams.page"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
const statusmap = ["待审核", "取消预约", "通过", "驳回", "教师撤销"]
import { getmybook, cancelopenexperimentbook } from "@/api/openexperimentbook"
import { gettreelist } from "@/api/common/semester"
import dayjs from "dayjs"
export default {
  name: "virtualsimulation",
  components: {

  },
  data () {
    return {
      total: 0,
      tableData: [],
      queryParams: {
        start_week: "",
        end_week: "",
        semester_id: "",
        status: '',
        // is_count: 1,
        page: 1,
        per_page: 10
      },
      is_current: [], // 当前学期
      optionone: [],
      optionstatus: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "取消预约",
        },
        {
          value: 2,
          label: "通过",
        },
        {
          value: 3,
          label: "驳回",
        },
      ],
      is_current_weeknum: "",
      loading: false,
    }
  },
  filters: {
    formatstatus (val) {
      return statusmap[val]
    },

    dataformat2 (value) {
      if (!value) return ""
      let time = dayjs.unix(value * 1000).format("MM,DD")
      return "(" + time + ")"
    },
    dataformat (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  created () {
    this.gettreelist() // 获取学期
  },
  methods: {
    formatSelect (_data) {
      this.optionone = _data.map(item => {
        if (item.is_current == 1) {
          this.is_current = item.id
          this.is_current_weeknum = item.week_number
        }
        let year = item.start_year + '-' + item.end_year
        let nper = item.nper == 1 ? '第一学期' : '第二学期'
        return {
          label: year + '──' + nper,
          value: item.id,
          is_current: item.is_current,
          week_number: item.week_number
        }
      })
      this.queryParams.semester_id = this.is_current
      this.getmybook()
    },
    // 获取学期列表与当前学期
    async gettreelist () {
      this.optionone = []
      const condition = sessionStorage.getItem('semester_all')
      if (condition) {
        let data = JSON.parse(condition)
        this.formatSelect(data.data)
      } else {
        let params = {}
        await gettreelist(params).then((response) => {
          if (response.code === 0 && response.data) {
            this.formatSelect(response.data.data)
          }
        }).catch((error) => { })
      }
    },
    // 获取列表数据
    getmybook () {
      this.loading = true
      getmybook(this.queryParams).then((response) => {
        if (response.code === 0 && response.data) {
          this.tableData = response.data.data
          this.total = response.data.total
          this.loading = false
        }
      }).catch((error) => {
        this.loading = false
      })
    },

    reportbt () {
      this.$router.push({
        path: "/personalcenter/experimentalreport",
      })
    },

    interactiveqabt () {
      this.$router.push({
        path: "/personalcenter/interactiveqa",
      })
    },
    cancelappointment (item) {
      this.cancelopenexperimentbook({
        id: item.id,
      })
    },
    change (item) {
      this.queryParams.semester_id = item
      this.is_current_weeknum = this.optionone.find(val => val.value == item).week_number
    },
    searchbt () {
      this.getmybook()
    },
    resetbt () {
      this.queryParams = {
        semester_id: this.is_current,
        start_week: "",
        end_week: "",
        status: '',
        // is_count: 1,
        page: 1,
        per_page: 10
      }
      this.getmybook()
    },
    changePage (val) {
      this.queryParams.page = val
      this.getmybook()
    },

    cancelopenexperimentbook (params) {
      cancelopenexperimentbook(params)
        .then((response) => {
          if (response.code === 0) {
            this.$message.success('取消预约成功')
            this.gettreelist()
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
  },
};
</script>
<style scoped lang="scss">
.virtualsimulation {
  .search {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;
    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
          margin-right: 10px;
          font-size: 16px;

          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 150px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;

          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 300px;
        }
      }
    }
    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;
      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        font-size: 14px;

        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        margin-left: 10px;
        background: #3d84ff;
        color: white;
      }
    }
  }

  .Table {
    margin-top: 50px;
    width: 100%;

    .item-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .title {
        margin-left: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }
    .item-middle {
      .title {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }
    .bt {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
    }

    .item-right {
      display: flex;
      justify-content: center;
      align-items: center;
      .bt {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }
      .line2 {
        margin-right: 8px;
        margin-left: 8px;
        height: 14px;
        border-right: 2px solid #cccccc;
      }
    }
  }
  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .elpagination-title {
      margin-right: 4px;
    }
  }
  // ::v-deep .el-button--text{
  //   color: rgb(61, 132, 255);
  // }
}
</style>

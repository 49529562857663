<template>
  <div class="maincontain">
    <div class="nametext">
      <span>{{ userinfo.name }}</span>
      <span class="textmargin">{{ userinfo.user_sn }}</span>
    </div>
    <div class="nametext">
      <span>{{ userinfo.belong }}</span>
    </div>
    <div class="line"></div>
    <el-form class="elform" ref="form" :model="form" label-width="80px">
      <el-form-item class="elinput" label="标题:">
        <el-input :disabled="type == 'check'" v-model="form.title" placeholder="请输入(20字以内)"></el-input>
      </el-form-item>

      <el-form-item class="elinput2" label="实验报告:">
        <div class="text-editor">
          <Tinymce
            ref="editor"
            :disabled="type == 'check'"
            v-model="form.content"
            style="height: auto; border-radius: 22px;font-size:16px;"
            @setInput="getValue"
          ></Tinymce>
        </div>
      </el-form-item>
      <el-form-item class="elinput" label="附件:">
        <template v-if="type != 'check'">
          <UploadFiles
            :files="fileList"
            :module="'practice_report/url'"
            :cloudType="'PracticeReport/url'"
            @setUrl="getUrl"
          />
        </template>
        <template v-else>
          <p v-for="(item, index) in fileList" :key="index" class="file-p">
            <i>{{ item.name }}</i>
            <i class="el-icon-view icon-size" @click="handelView(item)"></i>
            <i class="el-icon-download icon-size" @click="download(item)"></i>
          </p>
        </template>
      </el-form-item>
    </el-form>

    <el-dialog title="文件预览" :visible.sync="dialogVisible" width="50%" append-to-body>
      <!-- <FileView v-if="flag" :url="url" :type="file_type" /> -->
      <newFileView v-if="flag" :view-item="itemFile" @closeView="closeFileView" />
    </el-dialog>
  </div>
</template>

<script>
import { getuserinfo } from "@/api/user"
import {
  addexperimentreport,
  getexperimentreportinfo,
  editexperimentreport,
} from "@/api/experimentreport"
import Tinymce from "@/components/VueTinymce/index.vue"
import UploadFiles from "@/components/upload-files"
// import FileView from "@/components/file-view.vue"
import newFileView from "@/components/newFileView.vue"
export default {
  name: "mainpage",
  components: {
    Tinymce,
    UploadFiles,
    newFileView,
  },
  props: {
    close: {
      type: Function,
    },
    fresh: {
      type: Function,
    },
  },
  data () {
    return {
      userinfo: "",
      item: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/查看.png"),
      icon2: require("@/assets/下载.png"),
      form: {
        id: "",
        title: "",
        content: "",
        file: "",
      },
      fileList: [],
      type: "",
      dialogVisible: false,
      flag: false,
      itemFile: {},
    }
  },
  computed: {},
  created () {
    this.getuserinfo()
  },
  methods: {
    toback () {
      this.$router.push({
        name: "PersonalcenterMainpage",
        params: {
          id: 5,
          componentId: "Myinternship",
        },
      })
    },
    todetail () {
      this.$router.push({
        path: "/personalcenter/interactiveqadetails",
      })
    },
    getinfo (item, type) {
      this.type = type
      this.item = item
      if (type != "add") {
        this.checkInfo(item.report_id)
      }
    },
    savebt () {
      let params = {
        related_id: this.item.course_lib_weekly_lesson_id,
        type: 1,
        title: this.form.title,
        content: this.form.content,
        file: this.form.file,
      }
      if (this.type == "add") {
        params.action = 0
        this.addexperimentreport(params, 0)
      } else {
        params.id = this.form.id
        params.action = 1
        this.editexperimentreport(params, 0)
      }
    },
    eidtorbt () {
      let params = {
        related_id: this.item.course_lib_weekly_lesson_id,
        type: 1,
        title: this.form.title,
        content: this.form.content,
        file: this.form.file,
        action: 1,
      }
      if (this.type == "add") {
        this.addexperimentreport(params, 0)
      } else {
        params.id = this.form.id
        this.editexperimentreport(params, 0)
      }
    },
    getuserinfo (params) {
      getuserinfo(params)
        .then((response) => {
          if (response.code === 0) {
            this.userinfo = response.data
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    addexperimentreport (params, type) {
      addexperimentreport(params).then((response) => {
        if (response.code === 0) {
          this.close()
          this.fresh()
          if (type == 0) {
            this.$message({
              message: "保存成功",
              type: "success",
            })
          } else if (type == 1) {
            this.$message({
              message: "添加成功",
              type: "success",
            })
          }
        } else {
          this.close()
        }
      }).catch(() => {
        this.close()
      })
    },
    // 编辑
    editexperimentreport (params, type) {
      editexperimentreport(params).then((response) => {
        if (response.code === 0) {
          this.close()
          this.fresh()
          if (type == 0) {
            this.$message({
              message: "保存成功",
              type: "success",
            })
          } else if (type == 1) {
            this.$message({
              message: "修改成功",
              type: "success",
            })
          }
        } else {
          this.close()
        }
      }).catch(() => {
        this.close()
      })
    },
    //查看
    checkInfo (id) {
      getexperimentreportinfo({ id: id }).then((res) => {
        if (res.code === 0) {
          const data = res.data
          if (data) {
            this.form = {
              id: data.id,
              title: data.title,
              content: data.content,
              file: JSON.stringify(data.file_list),
            }
            this.fileList = data.file_list
          }
        }
      })
    },
    // 获取富文本得值
    getValue (data) {
      this.form.content = data
    },
    // 获取文件上传的返回值
    getUrl (data, key) {
      this.form.file = data
    },
    // 下载
    download (item) {
      var ele = document.createElement("a") // 创建下载链接
      ele.download = item.name //设置下载的名称
      ele.style.display = "none" // 隐藏的可下载链接
      ele.href = item.url
      // 绑定点击时间
      document.body.appendChild(ele)
      ele.click()
      // 然后移除
      document.body.removeChild(ele)
    },
    handelView (item) {
      this.itemFile = {
        url: item.url,
        ext: item.ext
      }
      this.dialogVisible = true
      this.flag = false
      this.$nextTick(() => {
        this.flag = true
      })
    },
    closeFileView (data) {
      this.dialogVisible = data
    },
  },
};
</script>
<style scoped lang="scss">
.maincontain {
  background: #ffffff;
  border-radius: 4px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  .nametext {
    margin-left: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;

    .textmargin {
      margin-left: 10px;
    }
  }

  .line {
    margin: 20px -20px 20px -20px;
    border-bottom: 1px solid #cccccc;
  }

  .elform {
    .elinput {
      width: 500px;
    }

    .elinput2 {
      width: 800px;
    }

    .file-p {
      i {
        margin-right: 30px;
        font-style: normal;
      }

      .icon-size {
        color: blue;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .downbt {
    margin-top: 30px;
    margin-left: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .elimage {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
</style>

<template>
  <!-- 个人中心-个人信息 -->
  <div class="main-contain">
    <el-tabs class="eltabs" v-model="activeName">
      <el-tab-pane label="基本设置" name="first">
        <el-row class="contain">
          <el-col class="left" :span="12">
            <div class="text1">基本设置</div>
            <template v-if="identifier == 3">
              <div v-show="fflag">
                <el-form class="outsite_con" label-position="right" label-width="80px" ref="form" :model="form"
                  :rules="rules">
                  <el-form-item label="用户编号">
                    <el-input class="el_con" v-model="form.user_sn" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="姓名">
                    <el-input class="el_con" v-model="form.name"></el-input>
                  </el-form-item>
                  <el-form-item label="邮箱">
                    <el-input class="el_con" v-model="form.email" disabled></el-input>
                    <template v-if="form.email">
                      <span class="email_text" @click="editor(2)">修改邮箱</span>
                    </template>
                    <template v-else>
                      <span class="email_text" @click="editor(2)">绑定邮箱</span>
                    </template>
                  </el-form-item>
                  <el-form-item label="身份信息">
                    <el-input class="el_con" v-model="form.user_type_name" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="学校">
                    <el-select style="width: 340px;" v-model="form.school_id" placeholder="请选择"
                      v-selectScroll="handleSchoolScroll" clearable filterable :filter-method="selectChange"
                      :loading="loading">
                      <el-option label="暂无学校信息" :value="Number(0)"></el-option>
                      <el-option v-for="item in schooloption" :key="item.id" :label="item.name"
                        :value="Number(item.id)"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="在校时间">
                    <el-date-picker class="el_con" v-model="timevalue" type="daterange" range-separator="至"
                      start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="专业">
                    <el-input class="el_con" v-model="form.major"></el-input>
                  </el-form-item>
                  <el-form-item label="学历">
                    <el-select class="el_con" v-model="form.edu_type_config_id" placeholder="请选择">
                      <el-option label="暂无学历信息" :value="Number(0)"></el-option>
                      <el-option v-for="item in educationoption" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="handleSubmit('form')" style="background: #3d84ff;">提交</el-button>
                    <!-- <el-button @click="handleReset('form')">取消</el-button> -->
                  </el-form-item>
                </el-form>
              </div>
              <div v-show="!fflag">
                <div class="text2">姓名：{{ form.name }}</div>
                <div class="text2">身份信息：{{ form.user_type_name }}</div>
                <div class="text2">学校：{{ form.school_name }}</div>
                <div class="text2">邮箱：{{ form.email }}</div>
                <div class="text2">在校时间：{{ form.at_school_start_date }} 至 {{ form.at_school_end_date }}</div>
                <div class="text2">专业：{{ form.major }}</div>
                <div class="text2">学历：{{ form.edu_type_name }}</div>
                <div class="text2">
                  <el-button type="primary" @click="fflag = true" style="background: #3d84ff;">编辑</el-button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text2" v-if="identifier == 2">工号：{{ userinfo.user_sn }}</div>
              <div class="text2" v-if="identifier == 1">学 号：{{ userinfo.user_sn }}</div>
              <div class="text2">姓 名：{{ userinfo.name }}</div>
              <div class="text2">手机号：{{ userinfo.mobile }}</div>
              <div class="text2">邮箱：{{ userinfo.email }}</div>
              <div class="text2">身份信息：{{ userinfo.user_type }}</div>
              <div class="text2" v-if="identifier == 2">职 称：{{ userinfo.job_name }}</div>
              <div class="text2" v-if="identifier == 2">所属学院：{{ userinfo.belong }}</div>
              <div class="text2" v-if="identifier == 1">学籍信息：{{ userinfo.belong }}</div>
            </template>
          </el-col>
          <el-col class="right userInfoImg" :span="12">
            <div>
              <UploadImg :imgSrc="imageUrl" :module="'user,avatar'" :cloudType="'user/avatar'" @setUrl="getUrl" />
            </div>
          </el-col>
        </el-row>
        <div class="update-btn" v-if="userinfo.user_type === 3">
          <el-button type="primary" @click="update_info">更新信息</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="安全设置" name="second">
        <div class="contain2">
          <el-row class="item">
            <el-col class="left" :span="12">
              <div class="title">账户密码</div>
              <div class="text">
                当前密码强度:
                <span class="text-s">{{ userinfo.password_strength }}</span>
              </div>
            </el-col>
            <el-col class="right" :span="12">
              <div class="btcontain">
                <div class="bt" @click="editor(0)">修改</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="item">
            <el-col class="left" :span="12">
              <div class="title">密保手机</div>
              <div class="text">已绑定手机：{{ userinfo.mobile }}</div>
            </el-col>
            <el-col class="right" :span="12">
              <div class="btcontain">
                <div class="bt" @click="editor(1)">修改</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="item">
            <el-col class="left" :span="12">
              <div class="title">绑定邮箱</div>
              <template v-if="userinfo.email">
                <div class="text">已绑定邮箱：{{ userinfo.email }}</div>
              </template>
              <template v-else>
                <div class="text">未绑定邮箱</div>
              </template>
            </el-col>
            <el-col class="right" :span="12">
              <template v-if="userinfo.email">
                <div class="btcontain">
                  <div class="bt" @click="editor(2)">修改</div>
                </div>
              </template>
              <template v-else>
                <div class="btcontain">
                  <div class="bt" @click="editor(2)">绑定邮箱</div>
                </div>
              </template>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
    <Customdialog ref="customdialog" type="min0" :title="title" width="700px" :showclose="true">
      <div slot="dialogbody" class="dialogbody">
        <!-- <Pass ref="pass"></Pass> -->
        <component :is="componentId" ref="component" :close="close" :is-ident="identifier" @setNewEmail="getNewEmail">
        </component>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <el-button @click="resetpass('ruleForm')">取消</el-button>
        <el-button type="primary" @click="submitpass('ruleForm')" style="background: #3d84ff;">确定</el-button>
      </div>
    </Customdialog>
  </div>
</template>
<script>

import { getschoollist } from "@/api/school"
import { gettypeconfiglist } from "@/api/typeconfig"
import UploadImg from "@/components/uploadFile"
import { getIdentifierToken, setWebAvaterToken } from "@/utils/auth"
import Customdialog from "@/components/customdialog.vue"
import dayjs from "dayjs"
import { getuserinfo, edituserinfo } from "@/api/user"
import Pass from "./pass"
import Mobile from "./mobile"
import eMail from "./email"
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
    Pass,
    Mobile,
    eMail,
    UploadImg,
  },
  data() {
    return {
      identifier: getIdentifierToken(),
      componentId: "Pass",
      title: "",
      imageUrl: "",
      activeName: "first",
      userinfo: {},
      educationoption: [],
      schoolparames: {
        keyword: "",
        page: 1,
        per_page: 10,
      },
      schooloption: [],
      schoolMoreItems: true, // 是否还有更多选项
      timevalue: [],
      isclear: false,
      form: {
        user_sn: "",
        name: "",
        email: "",
        avatar: "",
        avatarLocation: "",
        user_type: 3,
        user_type_name: "校外人员",
        school_id: 0,
        at_school_start_date: "",
        at_school_end_date: "",
        major: "",
        edu_type_config_id: "",
      },
      rules: {
        // name: [
        //   { required: true, message: '请输入用户姓名', trigger: ['blur', 'change'] }
        // ],
      },
      fflag: false,
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/图层 64.png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      icon3: require("@/assets/coursedetails/43443.png"),

      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
      loading: false,
    }
  },
  filters: {
    dataformat(value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  created() {
    this.getSchoolList() // 获取学校列表
    this.getTypeConfigList()
    this.getUserInfo() // 获取用户信息
  },
  methods: {
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ]
      let item = items[Math.floor(Math.random() * items.length)]
      return item
    },
    /* 用户信息-校外添加 */

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.update_info()
          this.fflag = false
          this.$message.success('保存信息成功!')
          // this.$store.dispatch("logout").then((res) => {
          //   this.$router.push({ path: "/login" })
          // })
        } else {
          return false
        }
      })
    },
    handleReset(formName) {
      this.$refs[formName].resetFields()
      this.fflag = false
    },
    /* 安全设置 */
    close() {
      this.$refs.customdialog.dialogclosebt()
    },
    editor(type) {
      this.$refs.customdialog.dialogopenbt()
      this.$nextTick(() => {
        // 刷新计时器，验证码
        let timer = this.$refs.component.timer
        if (timer) {
          this.$refs.component.count = ""
          this.$refs.component.show = true
          clearInterval(timer)
        }
        if (type == 0) {
          this.componentId = "Pass"
          this.title = "修改账户密码"
        } else if (type == 1) {
          this.componentId = "Mobile"
          this.title = "修改手机密码"
        } else if (type == 2) {
          this.componentId = "eMail"
          if (this.form.email) {
            this.title = "更换邮箱"
          } else {
            this.title = "绑定邮箱"
          }

          sessionStorage.setItem('oldEmail', this.identifier == 3 ? this.form.email : this.userinfo.email || "")
        }
        this.$refs.component.resetForm("ruleForm")
      })
    },
    submitpass() {
      this.$refs.component.submitForm("ruleForm")
    },
    resetpass() {
      this.$refs.component.resetForm("ruleForm")
      this.$refs.customdialog.dialogclosebt()
    },
    update_info() {
      if (this.identifier == 3) { //校外人员
        if (this.timevalue && this.timevalue.length > 0) {
          if (this.timevalue[0]) {
            this.form.at_school_start_date = dayjs(this.timevalue[0]).unix()
            // this.form.at_school_start_date = dayjs
            //   .unix(this.form.at_school_start_date)
            //   .format("YYYYMMDD");
          }
          if (this.timevalue[1]) {
            this.form.at_school_end_date = dayjs(this.timevalue[1]).unix()
            // this.form.at_school_end_date = dayjs
            //   .unix(this.form.at_school_end_date)
            //   .format("YYYYMMDD");
          }
        } else {
          this.form.at_school_start_date = ""
          this.form.at_school_end_date = ""
        }
        this.edituserinfo(this.form)
      } else { //非校外人员
        this.edituserinfo({
          name: this.userinfo.name,
          avatar: this.form.avatar,
        })
      }
    },
    getUserInfo(params) {
      getuserinfo(params)
        .then((response) => {
          switch (response.data.user_type) {
            case 0:
              response.data.user_type = "其他"
              break
            case 1:
              response.data.user_type = "学生"
              break
            case 2:
              response.data.user_type = "老师"
              break
            case 3:
              response.data.user_type = "校外人员"
              break
            case 4:
              response.data.user_type = "管理员"
              break
          }
          switch (response.data.password_strength) {
            case 0:
              response.data.password_strength = "弱"
              break
            case 1:
              response.data.password_strength = "一般"
              break
            case 2:
              response.data.password_strength = "强"
              break
          }
          this.imageUrl = response.data.avatar
          this.form.avatar = response.data.avatar
          response.data.school_name = response.data.school_name
            ? response.data.school_name
            : ""
          this.userinfo = response.data
          if (this.identifier == 3) {
            this.form.user_sn = response.data.user_sn
            this.form.name = response.data.name
            this.form.email = response.data.email
            this.form.school_id = response.data.school_id
            this.form.major = response.data.major
            this.form.edu_type_config_id = response.data.edu_type_config_id

            this.form.school_name = response.data.school_name
            this.form.edu_type_name = response.data.edu_type_name
            this.form.at_school_start_date = dayjs.unix(response.data.at_school_start_date).format("YYYY-MM-DD")
            this.form.at_school_end_date = dayjs.unix(response.data.at_school_end_date).format("YYYY-MM-DD")

            this.timevalue = [
              this.form.at_school_start_date,
              this.form.at_school_end_date,
            ]
          }
        })
        .catch((error) => { })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    //更新个人信息
    edituserinfo(params) {
      let that = this
      if (this.isclear) {
        params.avatar = null
      }
      edituserinfo(params).then((response) => {
        if (response.code === 0) {
          that.$store.commit("SET_Useravater", response.data.avatar)
          that.$store.commit("SET_Username", response.data.name)
          setWebAvaterToken(response.data.avatar)
          this.$message({
            message: "个人信息更新成功!",
            type: "success",
          })
        }
        this.getUserInfo()
      }).catch((error) => { })
    },
    // 获取学历列表
    getTypeConfigList() {
      let params = { type: 14, }
      gettypeconfiglist(params).then((response) => {
        if (response.code === 0) {
          this.educationoption = response.data
        }
      }).catch((error) => { })
    },
    selectChange(key) {
      this.loading = true
      this.schoolparames = {
        page: 1,
        keyword: key
      }
      getschoollist(this.schoolparames).then((response) => {
        if (response.code === 0 && response.data) {
          this.loading = false
          const odata = response.data.data || []
          this.schoolMoreItems = odata.length > 9 ? true : false
          this.schooloption = odata
        }
      }).catch((error) => { })
    },
    // 获取学校列表
    getSchoolList() {
      getschoollist(this.schoolparames).then((response) => {
        if (response.code === 0 && response.data) {
          const odata = response.data.data || []
          this.schoolMoreItems = odata.length > 9 ? true : false
          this.schooloption = this.schooloption.concat(odata)
        }
      }).catch((error) => { })
    },
    // 下拉加载
    handleSchoolScroll() {
      if (this.schoolMoreItems) {
        // 增加当前页码
        this.schoolparames.page++
        // 调用分页接口，传递搜索关键字和分页参数
        this.getSchoolList()
      }
    },
    signupname() {
      this.$router.push({
        path: "/home/personalcenter/registrationname",
      })
    },
    getUrl(data) {
      const url = JSON.parse(data)
      if (url.length > 0) {
        this.form.avatar = url[0].url
      }
      if (this.identifier !== 3) { //上传图片时校验如果是非校外人员直接更新图片
        this.edituserinfo({
          name: this.userinfo.name,
          avatar: this.form.avatar,
        })
      }
    },
    getNewEmail(data) {
      if (this.identifier == 3) {
        this.form.email = data
      } else {
        return
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;

  .eltabs {
    ::v-deep .el-tabs__item {
      font-size: 16px;
      padding: 0px;
      width: 150px;
      text-align: center;
    }

    .update-btn {
      padding: 0px 20px 20px;
    }

    .contain {
      height: 100%;
      background: #ffffff;
      padding: 15px 20px 20px;

      .left {
        height: 100%;

        // padding: 20px;
        .text1 {
          font-size: 20px;
          font-weight: 500;
          color: #222222;
        }

        .outsite_con {
          margin-top: 20px;

          .el_con {
            width: 340px;
          }

          .email_text {
            font-size: 14px;
            font-weight: 500;
            padding: 0px 12px;
            color: #409eff;
            cursor: pointer;
          }
        }

        .text2 {
          margin-top: 10px;
          font-size: 15px;
          font-weight: 500;
          color: #666666;
        }

        // .bt {
        //   margin-top: 40px;
        //   width: 88px;
        //   height: 42px;
        //   line-height: 42px;
        //   text-align: center;
        //   background: #3d84ff;
        //   border-radius: 4px;
        //   color: #ffffff;
        //   user-select: none;
        //   cursor: pointer;
        // }
      }

      .right {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .contain {
          margin-right: 100px;
          margin-top: 40px;

          .tiptext {
            text-align: center;
            font-size: 16px;
            color: #555555;
          }
        }
      }
    }

    .contain2 {
      height: 100%;
      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .item {
        height: 120px;
        border-bottom: 1px solid #d6d6d6;

        .left {
          height: 100%;

          .title {
            margin-top: 30px;
            font-size: 20px;
            font-weight: 500;
            color: #222222;
          }

          .text {
            margin-top: 20px;
            font-size: 15px;
            font-weight: 500;
            color: #666666;

            .text-s {
              color: #2fb600;
            }
          }
        }

        .right {
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          box-sizing: border-box;
          padding: 20px;

          .btcontain {
            .bt {
              width: 84px;
              height: 38px;
              line-height: 38px;
              text-align: center;
              background: #3d84ff;
              border-radius: 4px;
              color: #ffffff;
              user-select: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .customdialog {
    .dialogfooter {
      border-top: 1px solid #cccccc;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

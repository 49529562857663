<template>
  <!-- 虚拟仿真实验成绩 -->
  <div class="scoretable">
    <el-table
      ref="singleTable"
      :data="tableData"
      highlight-current-row
      style="width: 100%"
      class="Table"
      :header-cell-style="{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#F7F7F7',
      }"
      :row-style="{
        'font-size': '15px',
        color: '#222222',
        'font-weight': '400',
      }"
    >
      <el-table-column prop="module_name" label="实验名称"></el-table-column>
      <el-table-column prop="start_time" label="实验开始时间">
        <template slot-scope="scope">{{ scope.row.start_time | dataformat }}</template>
      </el-table-column>
      <el-table-column prop="end_time" label="实验结束时间" style>
        <template slot-scope="scope">{{ scope.row.end_time | dataformat }}</template>
      </el-table-column>
      <el-table-column prop="duration" label="实验用时(分钟)" align="center">
        <template slot-scope="scope">{{ scope.row.duration }}</template>
      </el-table-column>

      <el-table-column label="步骤成绩" align="center">
        <template slot-scope="scope">
          <div class="item-right">
            <div class="bt" @click="resultsbt(scope.row)">{{ scope.row.score }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="报告状态" align="center">
        <template slot-scope="scope">
          <div class="item-right">
            <div v-if="scope.row.report_status === 1">已提交</div>
            <div v-if="scope.row.report_status == -1 || scope.row.report_status == 0">未提交</div>
            <div v-if="scope.row.report_status === 2">已批阅</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="报告成绩" align="center">
        <template slot-scope="scope">
          <div class="item-right">{{ scope.row.report_score }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="item-right">
            <div
              class="bt"
              v-if="scope.row.report_status == 1 || scope.row.report_status == 2"
              @click="viewreport(scope.row)"
            >查看</div>
            <div
              class="bt"
              v-if="scope.row.report_status == -1"
              @click="newadd(scope.row,'1')"
            >新增实验报告</div>
            <div
              class="bt"
              v-if="scope.row.report_status == 0 || scope.row.report_status == 1"
              @click="newadd(scope.row,'2')"
            >编辑实验报告</div>
          </div>
        </template>
      </el-table-column>
      <el-dialog
        width="40%"
        title="实验报告查看"
        class="customdialog"
        :visible.sync="dialogTableVisible"
        append-to-body
      >
        <div class="dialogcontain">
          <div class="sectioncontain">
            <div class="title">实验标题:</div>
            <div class="content">{{ itemdetail.title }}</div>
          </div>
          <div class="sectioncontain dialogmain">
            <div class="title">实验报告:</div>
            <div class="content">
              <div v-html="itemdetail.content"></div>
            </div>
          </div>
          <div class="sectioncontain">
            <div class="title">附件:</div>
            <template v-if="itemdetail.file">
              <div v-for="(el, index) in itemdetail.file" :key="index">
                <span style="margin-right: 10px">附件：{{el.name}}</span>
                <el-button type="text" @click="seeFiles(el)">查看</el-button>
              </div>
            </template>
          </div>
          <div class="sectioncontain">
            <div class="title">报告评语:</div>
            <div class="content">{{itemdetail.reply}}</div>
          </div>
          <div class="sectioncontain">
            <div class="title">报告评分:</div>
            <div class="content">{{itemdetail.score}} 分</div>
          </div>
        </div>
      </el-dialog>
    </el-table>
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="form.page"
        @current-change="pageChange"
      ></el-pagination>
    </div>

    <Customdialog
      ref="customdialog"
      title="虚拟仿真实验成绩"
      class="custominnerdialog"
      type="max"
      :showclose="true"
      :appendtobody="true"
      min-width="60%"
    >
      <div slot="headtitle" class="innerheadtitle">实验步骤</div>
      <div slot="dialogbody" class="innerdialogbody">
        <div class="title">{{ info.module_name ? info.module_name : "模块标题" }}</div>
        <div class="head">
          <div class="item">
            <span>总分:&emsp;100</span>
            <span>得分:&emsp;{{ info.score }}</span>
          </div>
          <div class="item">
            <span>操作时间:&emsp; {{ info.start_time | dataformat }} ~ {{ info.end_time | dataformat }}</span>
          </div>
          <div class="item">操作时长:&emsp;{{ info.duration }}分钟</div>
        </div>
        <div class="content" style="height: 500px; overflow-y: scroll;">
          <div class="content_title">实验步骤</div>
          <div class="content_info" v-for="(item, index) in stepInfo" :key="index">
            <div class="content_info_item">
              <div class="info_item_title">{{ index * 1 + 1 }}.{{ item.title }}</div>
              <div class="info_item_body">
                <div v-if="item.remarks != '暂无'">学生答案:&emsp;{{ item.remarks }}</div>
                <div>操作次数:&emsp;{{ item.repeat_count }}</div>
                <div>操作时间:&emsp;{{ item.start_time | dataformat }} ~ {{ item.end_time | dataformat }}</div>
                <div>总分:&emsp;{{ item.max_score }}</div>
                <div>得分:&emsp;{{ item.score }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Customdialog>
    <el-dialog
      title="文件预览"
      :visible.sync="fileVisible"
      width="50%"
      class="file-view-dialog"
      append-to-body
    >
      <!-- <FileView v-if="flag" :url="url" :type="file_type" /> -->
      <newFileView v-if="flag" :view-item="itemFile" @closeView="closeFileView" />
    </el-dialog>
  </div>
</template>

<script>
import newFileView from "@/components/newFileView.vue"
// import FileView from "@/components/file-view.vue"
import dayjs from "dayjs"
import { getmyvrstudylist, getStepList } from "@/api/vrstudy"
import Customdialog from "@/components/customdialog.vue"
import {
  getvrstudyreportinfo,
} from "@/api/vrstudyreport"
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
    newFileView
  },
  data () {
    return {
      total: 0,
      tableData: [],
      itemdetail: "",
      info: {},
      stepInfo: {},
      form: {
        vr_id: '',
        page: 1,
        per_page: 10
      },
      dialogTableVisible: false,
      fileVisible: false,
      flag: false,
      itemFile: {},
    }
  },
  filters: {
    dataformat (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
    dataformat2 (value) {
      if (!value) return ""
      return dayjs.unix(value).format(" mm")
    },
  },
  methods: {
    getdata (item) {
      this.form.vr_id = item.id
      this.getmyvrstudylist()
    },
    //页码发生改变时
    pageChange (val) {
      this.form.page = val
      this.getmyvrstudylist()
    },
    resultsbt (row) {
      this.$refs.customdialog.dialogopenbt()
      this.info = row
      let params = {
        vr_study_id: row.id,
      }
      getStepList(params)
        .then((response) => {
          this.stepInfo = response.data

        })
        .catch((error) => {
          //console.log(error);
        })
    },
    newadd (row, type) {
      this.$router.push({
        path: "/home/personalcenter/veditorreport",
        query: {
          vr_id: this.form.vr_id,
          vr_study_id: row.id,
          itemid: row.report_id,
          type: type
        },
      })
    },
    viewreport (item) {
      //console.log("item", item)
      this.dialogTableVisible = true
      this.getvrstudyreportinfo({
        id: item.report_id,
      })
    },
    getvrstudyreportinfo (params) {
      getvrstudyreportinfo(params)
        .then((res) => {
          if (res.data) {
            let obj = res.data
            obj.content = this.AutoCorrection(obj.content)
            this.itemdetail = obj
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    getmyvrstudylist () {
      getmyvrstudylist(this.form)
        .then((response) => {
          this.total = response.data.total
          this.tableData = response.data.data
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    seeFiles (item) {
      this.itemFile = {
        url: item.url,
        ext: item.ext
      }
      this.fileVisible = true
      this.flag = false
      this.$nextTick(() => {
        this.flag = true
      })
    },
    closeFileView (data) {
      this.fileVisible = data
    },

    // 循环修正数据与数据格式
    AutoCorrection (str) {
      const that = this
      let newStr = str.replace(/[\r\n]/g, "").trim() // 去与换行与前后空格
      newStr = newStr.replace(/\\/g, "") // 去除反斜杠
      newStr = newStr.replace(/\s+/g, " ") // 去除多余空格
      // newStr = newStr.replace(/<p>(\s|\&nbsp\;| |　|\xc2\xa0)*<\/p>/ig, ""); // 去掉多余 P 标签
      // 判断是否有图片，如果有图片
      let isHavePic = newStr.includes('<img')
      if (isHavePic) {
        // 正则过滤出图片
        newStr.replace(/<img.+?>/ig, function (matchImg, captureImg) {
          // //console.log("正则匹配所有图片并获取相应下标==>",matchImg, captureImg);
          // newStr = newStr.replace(match,""); // 清除图片
          let resImgSrc = '' // 自定义变量接收图片
          matchImg.replace(/<img[^>]*src=['"]([^'"]+)[^>]*>/gi, function (matchSrc, captureSrc) {
            // //console.log("正则匹配该图片并获取相应路径==>",matchSrc, captureSrc);
            resImgSrc = captureSrc.replace("../uploads", that.$api.baseUrl + "/uploads")
          })
          // 在图片中插入点击事件并默认不展示
          let newImgStr = matchImg.replace(/<s?img[^>]*src=['"]([^'"]+)/gi,
            `<img src="${resImgSrc}" style="max-width:80%; margin: 4px;"`)
          newStr = newStr.replace(matchImg, newImgStr)
        })
      }
      // 判断是否有视频，如果有视频
      let isHaveMove = newStr.includes('<video')
      if (isHaveMove) {
        // 正则过滤出视频
        newStr.replace(/(<video[\s\S]*?>)[\s\S]*?(<\/video>)/ig, function (matchVideo, captureVideo) {
          // //console.log("正则匹配所有视频并获取相应下标==>",matchVideo, captureVideo);
          // newStr = newStr.replace(matchVideo,""); // 清空视频
          let resMoveurl = '' // 自定义变量接收视频
          matchVideo.replace(/<source [^>]*src=['"]([^'"]+)[^>]*>/gi, function (matchUrl, captureUrl) {
            // //console.log("正则匹配该视频并获取相应路径==>",matchUrl, captureUrl);
            resMoveurl = captureUrl.replace("../uploads", that.$api.baseUrl + "/uploads")
          })
          let newMoveStr = matchVideo.replace(/<s?video[\s\S]*?>[\s\S]*?<\/video>/gi,
            `<video controls="controls" style="max-width:80%; margin: 4px;"><source src="${resMoveurl}" type="video/mp4"/></video>`)
          newStr = newStr.replace(matchVideo, newMoveStr)
        })
      }
      return newStr
    },
  },
};
</script>
<style scoped lang="scss">
.scoretable {
  .search {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;

    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
          margin-right: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #888888;
        }

        .elinput {
          width: 220px;

          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
          margin-right: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #888888;
        }

        .elselect {
          width: 100px;
        }
      }
    }

    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;

      .bt {
        width: 64px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }

      .bt1 {
        margin-right: 10px;
        background: #3d84ff;
        color: white;
      }
    }
  }

  .Table {
    padding: 10px;
    margin-top: 30px;
    width: 100%;

    .bt {
      font-size: 15px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
    }
  }

  .elpagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .elpagination-title {
      margin-right: 4px;
    }
  }
}

.title {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}

.head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .item {
    margin-bottom: 15px;

    span {
      margin-right: 50px;
    }
  }
}

.content {
  .content_title {
    font-size: 26px;
    font-weight: bolder;
  }
}

.content_info {
  margin-top: 10px;

  .content_info_item {
    margin-bottom: 5px;

    .info_item_title {
      font-weight: bolder;
      margin-bottom: 15px;
      font-size: 16px;
    }

    .info_item_body {
      div {
        margin-bottom: 10px;
      }
    }
  }
}

.innerheadtitle {
  margin-left: 14px;
}

.innerdialogbody {
  border-top: 1px solid #cccccc;
  margin-top: 5px;
  min-height: 600px;
  padding: 20px;
}
.dialogcontain {
  padding: 10px 20px;

  .sectioncontain {
    width: 100%;

    .title {
      color: #222222;
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      line-height: 2;
    }

    .content {
      color: #222222;
      font-size: 14px;
      line-height: 2;
    }
  }

  .dialogmain {
    .content {
      text-indent: 2em;
    }
  }
}
.file-view-dialog {
  ::v-deep .el-dialog__body {
    padding-top: 0px;
  }
}
</style>
